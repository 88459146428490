<template>
  <div class="applyinformation">
    <div class="title">
      <div class="tits">
        申请信息
      </div>
      <a-button @click="$router.go(-1)">
        返回
      </a-button>
    </div>
    <div class="apply apply-box1">
      <div class="tit">
        保函基本信息
      </div>
      <div class="content">
        <a-form
          :label-col="{xs:{span:24},sm:{span:2},}"
          :wrapper-col="wrapperCol"
        >
          <a-form-item label="保函类型">
            <div>履约保函</div>
          </a-form-item>
          <a-form-item label="保证金额">
            <div>6,000元</div>
          </a-form-item>
          <a-form-item label="保证期限">
            <div>
              2022-3-23 ~ 2022-10-23 210天
            </div>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="apply apply-box2">
      <div class="tit">
        标的信息
      </div>
      <div class="content">
        <a-form
          :label-col="{xs:{span:24},sm:{span:8},}"
          :wrapper-col="wrapperCol"
        >
          <a-row :gutter="24">
            <a-col :span="8">
              <a-form-item label="项目名称">
                <div>建筑工程项目001</div>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="工程合同编号">
                <div>ZJ00001</div>
              </a-form-item>
            </a-col>
            <a-col :span="8" />
          </a-row>
          <a-row :gutter="24">
            <a-col :span="8">
              <a-form-item label="合同类型">
                <div>施工合同</div>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="合同价款(预计)">
                <div>10,000,000</div>
              </a-form-item>
            </a-col>
            <a-col :span="8" />
          </a-row>
          <a-row :gutter="24">
            <a-col :span="8">
              <a-form-item label="开工日期(预计)">
                <div>2022-3-23</div>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="竣工日期(预计)">
                <div>2022-10-01</div>
              </a-form-item>
            </a-col>
            <a-col :span="8" />
          </a-row>
          <a-row :gutter="24">
            <a-col :span="8">
              <a-form-item label="工期(预计)">
                <div>200天</div>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="是否公建项目">
                <div>否</div>
              </a-form-item>
            </a-col>
            <a-col :span="8" />
          </a-row>
          <a-row :gutter="24">
            <a-col :span="8">
              <a-form-item label="工程所在地区">
                <div>杭州市拱墅区</div>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="工程详细地址">
                <div>A街道A区</div>
              </a-form-item>
            </a-col>
            <a-col :span="8" />
          </a-row>
        </a-form>
      </div>
    </div>
    <div class="apply apply-box3">
      <div class="tit">
        申请资料
      </div>
      <div class="content">
        <a-form
          :label-col="{xs:{span:24},sm:{span:10},}"
          :wrapper-col="wrapperCol"
        >
          <a-row :gutter="24">
            <a-col :span="8">
              <a-form-item label="公司简介">
                <a-button type="link">
                  公司简介.pdf
                </a-button>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="营业执照">
                <a-button type="link">
                  营业执照.jpg
                </a-button>
              </a-form-item>
            </a-col>
            <a-col :span="8" />
          </a-row>
          <a-row :gutter="24">
            <a-col :span="8">
              <a-form-item label="公司章程">
                <a-button type="link">
                  公司章程.pdf
                </a-button>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="开户许可证">
                <a-button type="link">
                  开户许可证.jpg
                </a-button>
              </a-form-item>
            </a-col>
            <a-col :span="8" />
          </a-row>
          <a-row :gutter="24">
            <a-col :span="8">
              <a-form-item label="法人及实控人身份证正反面及简历">
                <a-button type="link">
                  法人信息.pdf
                </a-button>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="保证人身份证正反面、个人简历、结婚证、房产产证">
                <!-- <div>建筑工程项目001</div> -->
                <a-button type="link">
                  保证人信息.pdf
                </a-button>
              </a-form-item>
            </a-col>
            <a-col :span="8" />
          </a-row>
          <a-row :gutter="24">
            <a-col :span="8">
              <a-form-item label="企业人行征信">
                <a-button type="link">
                  人行征信.pdf
                </a-button>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="建筑资质证书">
                <a-button type="link">
                  建筑资质证书.jpg
                </a-button>
              </a-form-item>
            </a-col>
            <a-col :span="8" />
          </a-row>
          <a-row :gutter="24">
            <a-col :span="8">
              <a-form-item label="近两年工程项目明细">
                <a-button type="link">
                  项目明细.pdf
                </a-button>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="近3年审计报告(或年报)">
                <a-button type="link">
                  审计报告.pdf
                </a-button>
              </a-form-item>
            </a-col>
            <a-col :span="8" />
          </a-row>
          <a-row :gutter="24">
            <a-col :span="8">
              <a-form-item label="招标文件">
                <a-button type="link">
                  招标文件.pdf
                </a-button>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="施工合同">
                <a-button type="link">
                  施工合同.pdf
                </a-button>
              </a-form-item>
            </a-col>
            <a-col :span="8" />
          </a-row>
          <a-row :gutter="24">
            <a-col :span="8">
              <a-form-item label="中标书">
                <a-button type="link">
                  中标书.jpg
                </a-button>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="企业征信查询授权书">
                <a-button type="link">
                  授权书.pdf
                </a-button>
              </a-form-item>
            </a-col>
            <a-col :span="8" />
          </a-row>
        </a-form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
      },
    };
  },
  computed: {
    count() {
      return this.expand ? 11 : 7;
    },
  },
};
</script>
<style lang="less" scoped>
.applyinformation{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.apply {
  width: 80%;
  border: 1px solid #ebedf0;
  margin-bottom: 30px;
  padding: 30px 40px;
}
.title {
  display: flex;
  width: 85%;
  height: 80px;
  justify-content: space-between;
  align-items: center;
  .tits{
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 0px;
  }
}
.tit {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 30px;
}
.content{
    margin-left: 50px;
}
::v-deep .ant-form-item-label {
  text-align: left;
  white-space: initial;
}
</style>
